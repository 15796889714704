.sidebar {
  height: calc(100vh - 64px);
  overflow: auto;
  // border-right: 1px solid #bfbfbf;
  .ant-menu {
    background: #e6ebf1;
    font-size: 12px;
  }
  .submenu-item {
    padding-left: 48px !important;
  }
  .ant-menu-item {
    font-size: 12px;
    .ant-menu-submenu-title {
      color: rgba(29, 29, 29, 0.65);
      font-size: 12px;
      a:hover {
        color: #212121;
      }
    }
  }

  .ant-menu-item:hover {
    background-color: transparent !important;
    color: black;
    a {
      color: black;
    }
  }
  .ant-menu-item-selected {
    background-color: transparent !important;
    a {
      color: black;
      font-weight: 500;
    }
  }
  .ant-menu-submenu-active .ant-menu-submenu-title {
    background-color: transparent !important;
    color: black;
    font-weight: 500;
  }
  .ant-menu-item-selected:after {
    display: none;
  }
  .ant-layout-sider-zero-width-trigger {
    display: none;
  }
  .show-on-small-screen {
    display: none;
  }
  .ant-menu-vertical .ant-menu-item,
  .ant-menu-vertical-left .ant-menu-item,
  .ant-menu-vertical-right .ant-menu-item,
  .ant-menu-inline .ant-menu-item,
  .ant-menu-vertical .ant-menu-submenu-title,
  .ant-menu-vertical-left .ant-menu-submenu-title,
  .ant-menu-vertical-right .ant-menu-submenu-title,
  .ant-menu-inline .ant-menu-submenu-title {
    height: 40px;
    margin-top: 4px;
    margin-bottom: 4px;
    padding: 0 16px;
    overflow: hidden;
    font-size: 12px;
    line-height: 40px;
    text-overflow: ellipsis;
    font-weight: 400;
    letter-spacing: 0.032em;
  }
}
@media (max-width: 768px) {
  .sidebar .show-on-small-screen {
    display: block;
  }
  .sidebar-open {
    flex: 0 0 100% !important;
    max-width: 100% !important;
    min-width: 100% !important;
    width: 100% !important;
  }
}

@media screen and (min-width: 1360px) {
  .sidebar-open {
    flex: 0 0 13.5rem !important;
    max-width: 13.5rem !important;
    min-width: 13.5rem !important;
    width: 13.5rem !important;
  }
}

// @media only screen and (min-width: 1280px){
//   .sidebar-open{
//     flex: 0 0 200px !important;
//     max-width: 200px !important;
//     min-width: 200px !important;
//     width: 200px;
//   }
// }

// @media only screen and (min-width: 1440px){
//   .sidebar-open{
//     flex: 0 0 240px !important;
//     max-width: 240px !important;
//     min-width: 240px !important;
//     width: 240px;
//   }
// }

// @media only screen and (min-width: 1600px){
  
// }

// @media only screen and (min-width: 1920px){
  
// }
