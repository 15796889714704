.header.ant-layout-header {
  padding: 0 20px;
  background: #001e41;
  position: relative;
  z-index: 9;
  .navbar-ham {
    display: none;
    color: white;
    font-size: 24px;
  }
  // .logo {
  //   // display: inline-block;
  //   float: left;
  //   width: 17%;
  //   a {
  //     img {
  //       width: 85%;
  //       padding: 18px 23px;
  //     }
  //   }
  // }
  .ant-menu-dark {
    background: transparent;
    float: right;
  }
  .ant-menu-item {
    padding: 0 10px;
  }
  .ant-menu-item-selected,
  .ant-menu-item-active {
    background: #00162f;
  }
  .ant-menu-item .anticon {
    font-size: 14px;
  }
  .ant-avatar {
    background-color: rgb(252, 71, 71);
    border: 2px solid white;
    box-sizing: content-box;
  }
  .avatar-details {
    display: inline-block;
    margin: 0 10px;
    font-size: 12px;
    line-height: 20px;
    vertical-align: middle;
  }
}
.ant-dropdown-menu-item {
  a {
    padding: 10px 25px;
  }
}
@media (max-width: 768px) {
  .header .ant-menu {
    display: none;
  }

  .header.ant-layout-header .navbar-ham {
    display: block;
    float: right;
  }
}

@media only screen and (max-width: 1279px){
  .header.ant-layout-header{
    .logo {
      // display: inline-block;
      float: left;
      width: 160px;
      a {
        img {
          width: 100%;
          padding: 18px 23px;
        }
      }
    }
  }
}

@media only screen and (min-width: 1280px){
  .header.ant-layout-header{
    .logo {
      // display: inline-block;
      float: left;
      width: 240px;
      a {
        img {
          width: 200px;
          padding: 18px 23px;
        }
      }
    }
  }
}

@media only screen and (min-width: 1440px){
  
}

@media only screen and (min-width: 1600px){
  
}

@media only screen and (min-width: 1920px){
  
}
