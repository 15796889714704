.dashboard-header {
  background: #f7f7fc;
  color: #000;
}
.dashboard-content {
  background: #f0f2f5;
  padding: 56px;
  margin: 0;
  height: 100%;
  overflow: scroll;
}

.dashboard-content.no-sidebar {
  width: 100%;
  max-width: 100%;
  padding: 0;
}
@media (max-width: 768px) {
  .dashboard-content {
    padding: 15px;
  }
}

@media only screen and (min-width: 1280px){
  // .dashboard-content{
  //   padding: 32px 56px;
  // }
}

@media only screen and (min-width: 1440px){
  // .dashboard-content{
  //   padding: 32px 112px;
  // }
}

@media only screen and (min-width: 1600px){
  // .dashboard-content{
  //   padding: 32px 144px;
  // }
}

@media only screen and (min-width: 1920px){
  
}
