.login-page-container {
  width: 100%;
  height: 100%;
  background-image: url("../../assets/images/backgrounds/login_background.svg");
  background-color: #f7f7fc;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
}

.login-container {
  width: 536px;
  max-width: 100%;
  height: 100%;
  margin: auto;
  padding: 100px 15px 0 15px;

  .logo {
    margin-bottom: 18px;
    width: 200px;
  }
  .tagline {
    color: rgba(0, 0, 0, 0.45);
    margin: 0 0 42px 0;
  }
  .ant-tabs-bar {
    border: 0;
    text-align: center;
  }

  .login-form-forgot,
  .login-form-signup-here {
    float: right;
  }
  .login-form-button {
    width: 100%;
    margin: 25px 0;
  }

  .register-tag-line {
    margin: 15px 0 10px 0;
  }

  .login-page-footer {
    position: sticky;
    top: calc(100% - 55px);
    .footer-links {
      list-style-type: none;
      padding: 0;
      li {
        display: inline-block;
        margin: 0 20px;
        a {
          color: rgba(0, 0, 0, 0.45);
        }
        a:hover {
          color: rgb(0, 0, 0);
        }
      }
    }
  }
}
